<div class="grid grid-rows-1 w-full h-full overflow-auto chatbot">
  <student-chatbot-sidebar
    [isSmMenuHide]="isSmMenuHide"
    class="contents"
    [ngClass]="{ 'xl:contents': isCompact }"
    [isCompact]="isCompact"
  />
  <div class="w-full h-full gap-3 overflow-hidden flex justify-center p-4 sm:p-6" [ngClass]="{ 'xl:p-8': !isCompact }">
    <!-- <div
      class="flex flex-col xl:hidden gap-4 justify-start md:p-0 transition-all duration-300 overflow-hidden shrink-0"
      [ngClass]="{ 'h-[80vh] p-6': !isCollapse, 'h-0': isCollapse }"
    >
      <div class="flex flex-col flex-1 items-center gap-4"></div>
    </div> -->
    <div class="chatbox max-w-[720px] gap-2">
      <div class="flex justify-end">
        <button class="btn-icon link xl:hidden" (click)="toggleMenu()">
          <i class="icon-History"></i>
        </button>
        <!-- <span class="h7 hidden" [ngClass]="{ 'xl:hidden': isCompact, 'xl:inline': !isCompact }"
              >Năng lượng còn lại</span
            >
            <div class="flex-1"></div>
            <div class="w-[170px] h-[40px] relative">
              <img [src]="'/assets/images/' + themeService.getTheme() + '/Mana.svg'" alt="" class="h-[40px]" />
              <div class="battery-number">
                {{ batteryLife() }}
              </div>
            </div> -->
      </div>
      @if(!isLoadingMessages){
      <!--  -->
      @if(!isCompact){<ng-flutter
        *ngIf="flutterAppLoaded"
        src="/find-me-game/main.dart.js"
        assetBase="/find-me-game/"
        (appLoaded)="onFlutterAppLoaded($event)"
        class="find-me-game"
        [ngClass]="{ hidden: !isGaming }"
      ></ng-flutter
      >}
      <!--  -->
      <div class="chat-divider"></div>
      <messages
        [messages]="messages"
        [isThinking]="isThinking"
        (startGame)="sendMessage('/play find-me')"
        (isWritingEvent)="updateThinking($event)"
        [userId]="currentUser().uuid"
        [chatId]="chatId"
        [fileService]="fileService"
        (likeEvent)="onLikeMessage($event)"
        (undoEvent)="onUndoMessage($event)"
        (removeEvent)="onRemoveMessage($event)"
        [reviewMessage]="reviewMessage"
      />
      <div class="chat-divider"></div>
      <chatbox
        (sendMessage)="sendMessage($event)"
        (sendMathMessage)="sendMathMessage($event)"
        [isThinking]="isThinking"
        (endGame)="end()"
        [isGaming]="isGaming"
        (selectFile)="selectFile($event)"
        (selectImage)="selectImage($event)"
        (removeImage)="removeImage()"
        (selectFileFromStorage)="selectFileFromStorage($event)"
        [fileService]="fileService"
        [userId]="currentUser().uuid"
        [hasFileFeature]="true"
        (transcript)="transcript($event)"
        (sendReviewMessage)="sendReviewMessage($event)"
      />
      } @else if(chatId){
      <!-- <div class="place-self-center flex items-center">
          <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
        </div> -->
      <div class="body">
        <div class="message right">
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message left">
          <div class="avatar"><img src="assets/images/kyons-avatar.svg" alt="" /></div>
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message right">
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message left">
          <div class="avatar"><img src="assets/images/kyons-avatar.svg" alt="" /></div>
          <div class="content w-full">
            <div class="box w-full shine h-[120px]"></div>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
  <!-- <div class="xl:flex hidden flex-col flex-1 items-end">
    <h3>{{ theme === 'universe' ? 'Countdown' : 'Đếm ngược' }}</h3>
      <div class="flex gap-1 w-full items-center justify-center text-white">
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGrey-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ day }}</strong>
          {{ theme === 'universe' ? (parseInt(day) < 2 ? 'day' : 'days') : 'ngày' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGrey-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ hour }}</strong>
          {{ theme === 'universe' ? (parseInt(hour) < 2 ? 'hour' : 'hours') : 'giờ' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGrey-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ minute }}</strong>
          {{ theme === 'universe' ? (parseInt(minute) < 2 ? 'minute' : 'minutes') : 'phút' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGrey-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ second }}</strong>
          {{ theme === 'universe' ? (parseInt(second) < 2 ? 'second' : 'seconds') : 'giây' }}
        </div>
      </div>
  </div> -->
</div>
