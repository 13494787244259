import { pick } from "lodash-es";
import { formattedDate, toNonAccentVietnamese } from "../../../utils";
import { FileData } from "../../file/data";
import { IChat, IContent, IPart, Role } from "../domain";

enum LikeContent {
  LIKE = 1,
  NONE = 0,
  DISLIKE = -1
}

class AIModel {
  name: string;
  description: string;
  avatar: string;
  hashTag: string;
  constructor({ name, description, avatar, hashTag }: { name: string, description: string, avatar: string, hashTag: string }) {
    this.name = name;
    this.description = description;
    this.avatar = avatar;
    this.hashTag = hashTag;
  }

  static kyo(): AIModel {
    return new KyoModel();
  }

  static workspace(): AIModel {
    return new WorkspaceModel();
  }

  static fromJson(jsonObject: any) {
    const _ = pick(jsonObject, ['name', 'description', 'avatar', 'hashTag']);
    return new AIModel(_);
  }
}

class KyoModel extends AIModel {
  constructor() {
    super({ name: 'Kyo', description: 'Tìm kiếm tất cả mọi thứ trên đời', avatar: '/assets/images/kyons-avatar.svg', hashTag: '@KyoAI' });
  }
}

class WorkspaceModel extends AIModel {
  constructor() {
    super({ name: 'Workspace', description: 'Tổng hợp và phân tích chính xác dữ liệu từ tài liệu bạn tải lên', avatar: '/assets/images/Workspace Avatar.svg', hashTag: '@Workspace' });
  }
}

// {
//   "citation": 5,
//   "page": 38,
//   "text": "“Chị Greta cùng em sắp xếp việc ấy,” cô nói. “Greta khá tuyệt vời,” cô nói\ntiếp. “Nghĩ ra cách, anh biết đó. Chị ấy gợi hết ý này rồi ý khác.”\n“Cô Greta này là người trông thế nào?” tôi hỏi.\n“Ôi, chị Greta thật xinh đẹp. Cao ráo và tóc vàng. Chị ấy làm được mọi\nviệc.”\n“Chắc anh không thích cô ta đâu.”\nEllie cười. “Ồ, anh sẽ thích. Chắc chắn anh sẽ thích đấy. Chị ấy còn rất\nkhôn ngoan nữa.”\n“Anh không thích các cô gái khôn ngoan đâu. Cao ráo tóc vàng cũng chẳng\nưa. Anh thích các cô gái nhỏ nhắn có mái tóc màu lá úa mùa thu.”\n“Em tin chắc anh ghen với chị Greta,” cô nói.\n“Có lẽ vậy. Em mê cô ta lắm, đúng không?”\n“Vâng, em rất mê chị ấy. Chị đã tạo ra tất cả những điều khác biệt trong\ncuộc sống của em.”\n“Và chính cô ta đã gợi ý cho em xuống tận chỗ đó. Anh tự hỏi tại sao vậy?\nChẳng có gì nhiều để xem hay làm ở khu đất ấy. Anh thấy có hơi bí hiểm\nđó.”\n“Đấy là bí mật của bọn em,” Ellie nói vẻ bối rối.\n“Của em và Greta à? Nói cho anh biết đi!”\nCô lắc đầu. “Em phải giữ chút bí mật của em chứ.",
//   "fileId": "310462816ddc9466c697378ee2b3a7ccf1d2220c"
// },
class Candidate {
  text: string;
  fileId?: string;
  page?: number;
  citation: number;
  publicUrl: string;
  constructor({ text, fileId, page, citation, publicUrl }: { text: string, fileId?: string, page?: number, citation: number, publicUrl: string }) {
    this.text = text;
    this.fileId = fileId;
    this.page = page;
    this.citation = citation;
    this.publicUrl = publicUrl;
  }

  static fromJson(dataObject: any) {
    const _ = pick(dataObject, ['text', 'fileId', 'page', 'citation', 'publicUrl']);
    _.publicUrl = dataObject['public_url'] ?? '';
    return new Candidate(_);
  }
}

class Content implements IContent {
  id: string;
  role: Role;
  parts: Part[];
  isModel: boolean;
  isUser: boolean;
  createdAt: Date;
  like: LikeContent;
  model?: AIModel;
  candidates?: Candidate[];
  constructor({ id, role, parts, createdAt, like, model, candidates }: { id: string, role: Role, parts: (Part)[], createdAt: Date, like?: LikeContent, model?: AIModel, candidates?: Candidate[] }) {
    this.id = id;
    this.role = role;
    this.parts = parts;
    this.isModel = role === Role.model;
    this.isUser = role === Role.user;
    this.createdAt = createdAt;
    this.like = like ?? LikeContent.NONE;
    if (this.isModel) {
      this.model = model;
      this.candidates = candidates ?? [];
    }
  }
  static parseContent({ id, role, parts, createdAt, like, model, candidates }: { id: string, role: string; parts: { [key: string]: string }[], createdAt: { _seconds: number }, like?: LikeContent, model?: string, candidates?: any[] }): Content {
    return new Content(
      {
        id,
        role: role === "user" ? Role.user : Role.model,
        parts: parts.map((part) => Part.parsePart(part)),
        createdAt: new Date(createdAt._seconds * 1000), like: like ?? LikeContent.NONE,
        model: role === "model" ? parseModel(model) : undefined,
        candidates: role === "model" ? candidates?.map((jsonObject) => Candidate.fromJson(jsonObject)) : undefined
      }
    );
  }

  static outOfMana(): Content {
    return new Content(
      {
        id: 'outOfMana',
        role: Role.model,
        parts: [
          new TextPart('Bạn đã hỏi hết giới hạn ngày hôm nay mất rồi :-( Giới hạn sẽ được tự động hồi phục vào lúc 00:00 ngày mai. Hãy quay lại sau lúc đó để hỏi thêm câu hỏi bạn nha!')
        ],
        createdAt: new Date(),
      }
    );
  }

  static unknownError(code: number): Content {
    return new Content(
      {
        id: 'unknownError',
        role: Role.model,
        parts: [
          new TextPart(`Lỗi ${code}, vui lòng thử lại sau!`)
        ],
        createdAt: new Date(),
      }
    );
  }

  static empty() {
    return new Content({ id: '', role: Role.model, parts: [], createdAt: new Date() });
  }

  static review(text: string) {
    return new Content({ id: 'review', role: Role.user, parts: [new TextPart(text)], createdAt: new Date() });
  }
}

enum ChatErrorCodes {
  InvalidParam = 1,
  ChatNotFound = 2,
  NoMana = 3,
  FileTypeNotAllowed = 4,
  FileTooBig = 5,
  FileUploadStorageFailed = 6,
  FileUploadFirestoreFailed = 7,
  StorageLimitExceeded = 8,
  NoAnswer = 9,
  AIServerError = 10,
}

class ErrorContent extends Content {
  errorCode: ChatErrorCodes;
  constructor(errorCode: ChatErrorCodes, model: AIModel) {
    super({ id: new Date().getTime().toString(), role: Role.model, parts: [new TextPart(errorMessage(errorCode))], createdAt: new Date(), model });
    this.errorCode = errorCode;
  }
  static fromCode(errorCode: ChatErrorCodes, model: AIModel) {
    return new ErrorContent(errorCode, model);
  }
}

class CommandContent extends Content {
  command: string;
  commandMessage: string;
  hasCallback: boolean = false;
  constructor(command: string, model: AIModel) {
    super({ id: new Date().getTime().toString(), role: Role.model, parts: [new TextPart(commandMessage(command))], createdAt: new Date(), model });
    this.command = command;
    this.commandMessage = commandMessage(command);
    this.hasCallback = command.startsWith('/play');
  }

  static noCommandFound(model: AIModel) {
    return new Content({ id: '', role: Role.model, parts: [new TextPart('Không tìm thấy lệnh')], createdAt: new Date(), model });
  }
  static reply(res: string, model: AIModel): Content {
    return new Content({ id: '', role: Role.model, parts: [new TextPart(res)], createdAt: new Date(), model });
  }
}

function errorMessage(errorCode: ChatErrorCodes): string {
  if (errorCode == ChatErrorCodes.NoMana) {
    return 'Khoan, hết mana rồi, **nạp thêm mana nhé!** Hoặc bạn hãy quay lại sau **24 giờ đêm nay** để có thêm mana sử dụng 🥹';
  }
  else if (errorCode == ChatErrorCodes.FileTypeNotAllowed) {
    return '### Định dạng không phù hợp \n Tài liệu [fileName] có định dạng khác .pdf. Vui lòng thử lại'
  }
  else if (errorCode == ChatErrorCodes.FileTooBig) {
    return '### Tài liệu quá lớn! \n Tài liệu [fileName] lớn hơn [allowFileSize]. Vui lòng thử lại'
  }
  else if (errorCode == ChatErrorCodes.StorageLimitExceeded) {
    return '### Bạn đã vượt quá dung lượng lưu trữ!'
  }
  else if (errorCode == ChatErrorCodes.FileUploadFirestoreFailed || errorCode == ChatErrorCodes.FileUploadStorageFailed) {
    return '### Tài liệu tải lên thất bại!'
  }
  else {
    return 'Ủa, mình đang gặp lỗi, bạn thử lại nhé!';
  }
}

function commandMessage(command: string): string {
  if (command == '/games') {
    return `Danh sách các game:\n
    - Can you find me: Game đuổi bắt giải trí [/play find-me]
    `
  }
  else {
    return '';
  }
}

class Part implements IPart {
  toJson() {
    return {};
  }
  isText = false;
  isData = false;
  isDeleted = false;
  text?: string;
  fileId?: string;
  url?: string;
  mimeType?: string;
  data?: FileData;
  name?: string;
  textDisplay?: string;
  static parsePart(jsonObject: any) {

    // return switch (jsonObject) {
    //   {'text': final String text} => TextPart(text),
    //   {'inlineData': {'mimeType': String _, 'data': String _}} =>
    //     throw UnimplementedError('inlineData content part not yet supported'),
    //   _ => throw FormatException('Unhandled Part format', jsonObject),
    // };
    if (jsonObject.text) {
      return new TextPart(jsonObject.text);
    } else if (jsonObject.fileId) {
      return new FilePart(jsonObject.fileId);
    }
    throw new Error('Unhandled Part format');
  }
}

class TextPart extends Part {
  // override url: string;
  hasPlayBtn: boolean;
  override isText = true;
  constructor(text: string) {
    super();
    this.text = text;
    this.hasPlayBtn = this.text.includes('/play');
  }
  override toJson() {
    return { text: this.text };
  }
}

class FilePart extends Part {
  // mimeType?: string;
  // url?: string;
  override isData = true;
  id: string;
  base64?: string;

  constructor(id: string) {
    super();
    this.id = id;
  }

  override toJson(): Record<string, unknown> {
    return {
      'fileId': this.id
    }
  }

  static empty() {
    return new FilePart('');
  }
}

class Chat implements IChat {
  id: string;
  createdAt: Date;
  firstMessage: string;
  search: string;
  messages: Content[] = [];
  dateDisplay: string;
  askModel: AIModel;
  constructor(id: string, createdAt: Date, firstMessage: string, askModel: AIModel) {
    this.id = id;
    this.firstMessage = firstMessage;
    this.createdAt = createdAt;
    this.dateDisplay = formattedDate(this.createdAt);
    this.search = toNonAccentVietnamese(this.firstMessage).toLocaleLowerCase();
    this.askModel = askModel;
  }
  static fromJson({
    id,
    createdAt,
    firstMessage,
    askModel
  }: {
    id: string;
    createdAt: { _seconds: number };
    messages: { role: string; parts: { text: string }[] }[];
    firstMessage: string | null;
    askModel: any;
  }) {
    const createdAtDate = new Date(createdAt._seconds * 1000);
    const model = AIModel.fromJson(askModel);
    return new Chat(id, createdAtDate, firstMessage ?? '', model);
  }

  updateMessages(messages: Content[]) {
    this.messages = messages;
  }
}

class Mana {
  value: number;
  max: number;
  constructor(value: number, max: number) {
    this.value = value;
    this.max = max;
  }
  static invalid() { return new Mana(-1, -1) };
}

// enum ChatErrorCode {
//   UserNotFound = 1,
//   NoPrompt = 2,
//   OutOfMana = 3,
// }

// class ChatError {
//   message: string;
//   code: ChatErrorCode;
//   constructor(message: string, code: number) {
//     this.message = message;
//     this.code = code;
//   }
// }

function parseModel(modelName: string | undefined): AIModel {
  switch (modelName) {
    case 'workspace':
      return AIModel.workspace();
    default:
      return AIModel.kyo();
  }
}


export { AIModel, Candidate, Chat, ChatErrorCodes, CommandContent, Content, ErrorContent, FilePart, KyoModel, LikeContent, Mana, Part, TextPart, WorkspaceModel };

