import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { ChatService } from '@data/chat/chat.service';
import { IAuthCredential, IAuthService } from '@domain/auth/i-auth-service';
import { sandboxAccounts } from '@domain/auth/sandbox-account';
import { environment } from '@environments';
import { browserPopupRedirectResolver, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { firstValueFrom, map } from 'rxjs';
import { KnowledgeService } from '../knowledge/knowledge.service';
import { TrackingService } from '../tracking/tracking.service';
import { UserService } from '../user/user.service';
import { EmailPasswordCredential } from './credential';
import { serverApi } from './interceptor';

export const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_ROLE = 'role';
const EXPIRED_TIME = 'expired_time';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  emailSignIn(value: any): Promise<unknown> {
    return signInWithEmailAndPassword(this.afAuth, value.email, value.password);
  }
  http = inject(HttpClient);
  afAuth = inject(Auth);
  userService = inject(UserService);
  knowledgeService = inject(KnowledgeService);
  trackingService = inject(TrackingService);
  backend = inject(HttpBackend);
  chatService = inject(ChatService);

  signIn(credential: IAuthCredential) {
    if (credential instanceof EmailPasswordCredential) {
      if (sandboxAccounts.includes(credential.email)) {
        window.localStorage.setItem('dev', 'true');
      } else {
        window.localStorage.removeItem('dev');
      }
    }
    this.clearStorage();
    const hostName = serverApi();
    const _http = new HttpClient(this.backend);
    return _http.post(`${hostName}/auth/sign_in`, credential.toJson()).pipe(
      // catchError(DBHelper.handleError('POST sign_in', Error('Server Error'))),
      map((data: any) => {
        if (TOKEN_KEY in data && REFRESH_TOKEN_KEY in data) {
          if (data[USER_ROLE] !== environment.name)
            return {
              error: true,
              message: 'Domain specific error.',
            };
          this.setToken(data, data['expires_in']);
          this.setRefreshToken(data);
          this.userService.updateCurrentUser(data['id']);
          this.trackingService.init();
        }
        return data;
      })
    );
  }

  signOut({ hasToken = true }: { hasToken?: boolean } = {}) {
    if (hasToken) {
      firstValueFrom(this.http.post(`${serverApi()}/auth/sign_out`, {})).then(() => {
        if (window.localStorage.getItem('dev') === 'true') {
          window.localStorage.removeItem('dev');
        }
        this.clearStorage();
      });
    }
    else {
      if (window.localStorage.getItem('dev') === 'true') {
        window.localStorage.removeItem('dev');
      }
      this.clearStorage();
    }
    this.afAuth.signOut();
  }

  clearStorage() {
    this.removeToken();
    this.removeRefreshToken();
    this.userService.removeCurrentUser();
    this.trackingService.resetTracking();
    this.knowledgeService.removeSelectedProgram();
    this.knowledgeService.removeSelectedLearningGoal();
    this.chatService.removeCache();
  }

  public getToken() {
    return window.localStorage.getItem(TOKEN_KEY) ?? '';
  }

  public getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  public setToken(data: Record<string, string>, timeout = 0) {
    window.localStorage.setItem(TOKEN_KEY, data[TOKEN_KEY]);

    if (timeout > 0) {
      const expiredIn = new Date().getTime() + timeout * 1000;
      window.localStorage.setItem(EXPIRED_TIME, expiredIn.toString());
    }
  }

  public setRefreshToken(data: Record<string, string>) {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, data[REFRESH_TOKEN_KEY]);
  }

  public removeToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  }

  public removeRefreshToken() {
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  refreshToken(refreshToken: string) {
    // const http = new HttpClient(this.backend);
    // const contentType = 'application/json';
    // const options = {
    //   headers: new HttpHeaders().set(TOKEN_HEADER_KEY, `Bearer ${refreshToken}`).set('Content-Type', contentType),
    // };
    return this.http.post(`${serverApi()}/auth/refresh`, { refresh_token: refreshToken });
  }

  isTimedOut(): boolean {
    const expiredTime = window.localStorage.getItem(EXPIRED_TIME);
    if (expiredTime) {
      const expired = new Date().getTime() > Number(expiredTime);
      return expired;
    }
    return false;
  }

  async googleSignIn(): Promise<{ accessToken: string | undefined, idToken: string | undefined } | FirebaseError> {
    try {
      console.log('googleSignIn');
      // browserPopupRedirectResolver;
      // Sign in using a redirect.
      const provider = new GoogleAuthProvider();
      // Start a sign in process for an unauthenticated user.
      provider.addScope('profile');
      provider.addScope('email');

      const userCredential = await signInWithPopup(this.afAuth, provider, browserPopupRedirectResolver);
      const credential = GoogleAuthProvider.credentialFromResult(userCredential);
      if (credential) {
        return { accessToken: credential.accessToken, idToken: credential.idToken };
      }
      else {
        return { accessToken: undefined, idToken: undefined };
      }
      // return await this.afAuth.currentUser?.getIdToken();
    }
    catch (error) {
      if (error instanceof FirebaseError) {
        return error;
      }
      return { accessToken: undefined, idToken: undefined };
    }
  }
  async facebookSignIn(): Promise<{ accessToken: string | undefined, idToken: string | undefined } | FirebaseError> {
    try {
      console.log('facebookSignIn');
      // browserPopupRedirectResolver;
      const provider = new FacebookAuthProvider();
      // Start a sign in process for an unauthenticated user.
      provider.addScope('email');
      provider.addScope('public_profile');
      const userCredential = await signInWithPopup(this.afAuth, provider, browserPopupRedirectResolver);
      const credential = FacebookAuthProvider.credentialFromResult(userCredential);
      if (credential) {
        return { accessToken: credential.accessToken, idToken: credential.idToken };
      }
      else {
        return { accessToken: undefined, idToken: undefined };
      }
    }
    catch (error) {
      if (error instanceof FirebaseError) {
        return error;
      }
      return { accessToken: undefined, idToken: undefined };
    }
  }

  // async auth(access_token: string): Observable<any> {
  //   try {
  //     // console.log(adminToken);

  //     const credential = {
  //       "provider": "google_oauth2",
  //       access_token,
  //     };

  //     this.clearStorage();
  //     const hostName = serverApi();
  //     const _http = new HttpClient(this.backend);
  //     return _http.post(`${hostName}/auth/sign_in`, credential).pipe(
  //       // catchError(DBHelper.handleError('POST sign_in', Error('Server Error'))),
  //       map((data: any) => {
  //         if (TOKEN_KEY in data && REFRESH_TOKEN_KEY in data) {
  //           if (data[USER_ROLE] !== environment.name)
  //             return {
  //               error: true,
  //               message: 'Domain specific error.',
  //             };
  //           this.setToken(data, data['expires_in']);
  //           this.setRefreshToken(data);
  //           this.userService.updateCurrentUser(data['id']);
  //           this.trackingService.init();
  //         }
  //         return data;
  //       })
  //     );
  //   } catch (error) {
  //     if (error instanceof FirebaseError) {
  //       const code = error.code;
  //       const errorMessage = error.message;
  //       // eslint-disable-next-line valid-typeof
  //       if (typeof error === 'object') {
  //         // console.log(error.email);
  //       }
  //       const credential = GoogleAuthProvider.credentialFromError(error);
  //       const email = credential != null ? credential!.signInMethod : '';
  //       console.log(code, errorMessage, email, credential);
  //     }
  //     return error;
  //   }
  // }
  loginZalo() {
    // https://oauth.zaloapp.com/v4/permission?app_id=<APP_ID>&redirect_uri=<CALLBACK_URL>&code_challenge=<CODE_CHALLENGE>&state=<STATE>
    // code_challenge = Base64.encode(SHA-256.hash(ASCII(code_verifier)))
    // const codeVerifier = 'code_verifier';
    // const codeChallenge = ;
    // window.open(`https://oauth.zaloapp.com/v4/permission?app_id=${environment.zalo.APP_ID}&redirect_uri=${environment.zalo.CALLBACK_URL}&code_challenge=
    // ${codeChallenge}&state=<STATE>`, '_blank');
  }
}
