import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  computed,
  effect,
  inject,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ManaController } from '@app/mana.controller';
import { ChatService, chatServerApi } from '@data/chat/chat.service';
import { FileService } from '@data/file/file.service';
import { NavigationService } from '@data/navigation/navigation.service';
import { ThemeService } from '@data/theme/theme.service';
import { TrackingService } from '@data/tracking/tracking.service';
import { UserService } from '@data/user/user.service';
import { Role, maxManaWidth } from '@domain/chat/i-content';
import { ChatboxComponent, MessagesComponent, NgFlutterComponent } from '@share-components';
import { AIModel, CommandContent, Content, FileData, FilePart, FilePlaceholder, LikeContent, Part, TextPart } from '@share-utils/data';
import { cancel } from '@share-utils/utils';
import { isCommand } from '@utils/chat';
import { TopMenuComponent } from '@view/share-components/top-menu/top-menu.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ChatbotSidebarComponent } from './sidebar/chatbot-sidebar.component';
@Component({
  selector: 'student-chatbot',
  standalone: true,
  imports: [CommonModule, NgFlutterComponent, RouterModule, TopMenuComponent, MessagesComponent, ChatboxComponent, ChatbotSidebarComponent],
  templateUrl: 'chatbot.component.html',
  styleUrl: 'chatbot.component.scss',
})
export class ChatbotComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'contents chat';
  @ViewChild(ChatbotSidebarComponent) sidebar!: ChatbotSidebarComponent;

  changeDetectorRef = inject(ChangeDetectorRef);
  chatService = inject(ChatService);
  paths = inject(NavigationService).paths;
  route = inject(ActivatedRoute);
  router = inject(Router);
  userService = inject(UserService);
  injector = inject(Injector);
  themeService = inject(ThemeService);
  zone = inject(NgZone);
  document = inject(DOCUMENT);
  renderer = inject(Renderer2);
  fileService = inject(FileService);
  trackingService = inject(TrackingService);
  manaCtrl = inject(ManaController);
  titleService = inject(Title);

  flutterState?: any;
  countdown!: Observable<number>;
  $interval!: Subscription;
  flutterAppLoaded = true;
  day = '';
  hour = '';
  minute = '';
  second = '';
  chatId = '';
  currentUser = this.userService.currentUser;
  theme!: string;
  parseInt = parseInt;
  isCollapse = true;
  messages: Content[] = [];
  isThinking = false;
  isGaming = false;
  isSmMenuHide = signal(true);
  file: File | undefined = undefined;
  image: FilePlaceholder | undefined = undefined;
  isLoadingMessages = false;
  fileData: FileData | undefined = undefined;
  // mana = this.manaCtrl.mana$;
  manaWidth = computed(() => Math.floor(maxManaWidth * this.manaCtrl.mana$().value / this.manaCtrl.mana$().max));
  batteryLife = computed(() => this.manaCtrl.mana$().value);
  sendMessageSubscription?: Subscription | null = null;
  reviewMessage = '';
  selectedModels = [AIModel.kyo()];

  @Input() isCompact = false;

  ngOnInit(): void {
    this.titleService.setTitle('KYONS AI - Chat với Kyo');
    this.userService.updateCurrentUser(this.currentUser().uuid).then((res) => {
      if (res) {
        console.log('User updated');
      }
      else {
        console.log('User not updated');
      }
    }, (err) => {
      console.error(err);
      this.router.navigate([this.paths.signOut.path]);
    });
    // watch route param changes
    this.route.paramMap.subscribe(params => {
      this.isLoadingMessages = true;
      this.flutterAppLoaded = false;
      this.isGaming = false;
      this.chatId = params.get('id') ?? '';
      if (this.chatId) {
        this.getMessages().add(() => this.isLoadingMessages = false);
      }
      else {
        setTimeout(() => {
          this.isLoadingMessages = false;
        }, 500);
      }

      setTimeout(() => {
        this.flutterAppLoaded = true;
      }, 500);
      this.theme = this.themeService.themeStore();
    });
    // const targetDate = new Date('2024-04-22T24:00:00+00:00');
    // const countdown = interval(1000);

    // this.$interval = countdown.subscribe(() => {
    //   const now = new Date();
    //   const diff = targetDate.getTime() - now.getTime();
    //   const day = Math.floor(diff / (1000 * 60 * 60 * 24));
    //   const hour = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //   const minute = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    //   const second = Math.floor((diff % (1000 * 60)) / 1000);
    //   this.day = day.toString().padStart(2, '0');
    //   this.hour = hour.toString().padStart(2, '0');
    //   this.minute = minute.toString().padStart(2, '0');
    //   this.second = second.toString().padStart(2, '0');
    //   if (diff < 0) {
    //     if (this.$interval !== undefined) this.$interval.unsubscribe();
    //     this.day = '00';
    //     this.hour = '00';
    //     this.minute = '00';
    //     this.second = '00';
    //   }
    // });
  }
  // updateMana() {
  //   this.chatService.getMana(this.currentUser().uuid).subscribe({
  //     next: (mana: Mana) => {
  //       this.mana = mana;
  //       this.manaWidth = Math.floor(maxManaWidth * mana.value / mana.max);
  //       this.batteryLife = Math.floor(mana.value / mana.max * 100);
  //     },
  //     error: (err) => {
  //       console.error(err);
  //     },
  //   });
  // }

  ngOnDestroy(): void {
    cancel([this.$interval]);
    if (this.sendMessageSubscription) this.sendMessageSubscription.unsubscribe();
  }

  onFlutterAppLoaded(state: any) {
    this.flutterState = state;

    if (this.chatId) {
      console.log(`Chat id: ${this.chatId}`);
      this.flutterState.setChatId(this.chatId);
    } else {
      // setTimeout(() => {
      //   if (this.messages.length == 0) {
      this.getGreeting();
      //   }
      // }, 6000);
    }

    // Set the initial values of the Flutter app from enum DemoScreen in dart file
    this.flutterState.setUserId(this.currentUser().uuid);
    // this.flutterState.setChatId(this.chatId);
    this.flutterState.setServerApi(chatServerApi);
    // this.flutterState.setTheme(this.themeService.themeStore());
    runInInjectionContext(this.injector, () => {
      effect(() => {
        this.theme = this.themeService.themeStore();
        this.flutterState.setTheme(this.themeService.themeStore());
      });
    });
    // this.flutterState.onChatIdChanged(() => {
    //   this.flutterAppLoaded = false;
    //   console.log(`Chat id changed: ${this.flutterState.getChatId()}`);
    //   if (this.chatId != this.flutterState.getChatId()) {
    //     this.goToChat(this.flutterState.getChatId());
    //   }
    // });
    // this.flutterState.onManaChanged(() => {
    //   const { a, b } = this.flutterState.getMana();
    //   // this.manaWidth = maxManaWidth * a / b;
    //   // this.batteryLife = (a / b * 100).toFixed(0) as unknown as number;
    //   if (a != this.manaCtrl.mana$().value) {
    //     console.log('Mana changed', a, b, this.manaCtrl.mana$());
    //     this.manaCtrl.refresh();
    //     this.getMessages();
    //   };
    // });
    // this.flutterState.onThemeChanged(() => {
    //   this.theme = this.flutterState.getTheme();
    //   this.themeService.setTheme(this.theme);
    // });
    this.flutterState.onShowGameChanged(() => {
      this.isGaming = this.flutterState.shouldShowGame();
      if (!this.isGaming) { this.flutterState.setMessage(''); }
      // this.getMessages();
    });
    this.flutterState.onWinGame(() => {
      this.sendMessage('/win find-me');
    })
  }

  getGreeting() {
    if (this.chatId == '') {
      this.isThinking = true;
      this.chatService.getGreeting().subscribe({
        next: (message) => {
          this.messages = [message];
        },
        error: (err) => {
          console.error(err);
          this.isThinking = false;
        },
      })
    }
  }

  sendMathMessage(message: string) {
    if (this.isThinking) return;
    this.isThinking = true;
    const askingMessage: Part[] = [new TextPart(message)];
    this.messages = [...this.messages, new Content({ id: '', role: Role.user, parts: askingMessage, createdAt: new Date() })];
    this.sendMessageSubscription = this.chatService.sendMathMessage(this.currentUser().uuid, message, { chatId: this.chatId }).subscribe({
      next: async (chatId) => {
        if (this.chatId == '') {
          this.chatId = chatId;
          await this.sidebar.getChats();
          firstValueFrom(this.fileService.listFiles(chatId));
          this.router.navigate([this.paths.chat.path.replace(':id', chatId)]);
        }
        else {
          this.getMessages();
          this.manaCtrl.refresh();
        }
        this.trackingService.updateTrackOnSendMessage()
      },
      error: (err) => {
        console.error(err);
        if (err.code === 3) {
          this.messages = [...this.messages, Content.outOfMana()]
        }
        else {
          this.messages = [...this.messages, Content.unknownError(err.code)]
        }
        this.isThinking = false;
      },
    });
  }

  sendMessage(message: string) {
    if (this.chatService.isCommand(message)) {
      this.runCommand(message);
      return;
    }
    if (this.isThinking) return;
    this.isThinking = true;
    const askingMessage: Part[] = [new TextPart(message)];
    if (this.image) {
      const newFilePart = new FilePart(this.image.name);
      newFilePart.url = this.image.base64 || this.image.uri;
      newFilePart.mimeType = this.image.mimeType;
      newFilePart.name = this.image.name;
      askingMessage.push(newFilePart);
    }
    if (!isCommand(message)) this.messages = [...this.messages, new Content({ id: '', role: Role.user, parts: askingMessage, createdAt: new Date() })];
    // console.log(message);
    // if (this.chatId) {
    this.sendMessageSubscription = this.chatService.sendMessage(this.currentUser().uuid, message, AIModel.kyo().hashTag, {
      chatId: this.chatId,
      file: this.file,
      image: this.image,
      fileData: this.fileData,
    }).subscribe({
      next: async (chatId) => {
        if (this.chatId == '') {
          this.chatId = chatId;
          await this.sidebar.getChats();
          firstValueFrom(this.fileService.listFiles(chatId));
          this.router.navigate([this.paths.chat.path.replace(':id', chatId)]);
        }
        else {
          this.getMessages();
          this.manaCtrl.refresh();
        }
        this.trackingService.updateTrackOnSendMessage()
      },
      error: (err) => {
        console.error(err);
        if (err.code === 3) {
          this.messages = [...this.messages, Content.outOfMana()]
        }
        else {
          this.messages = [...this.messages, Content.unknownError(err.code)]
        }
        this.isThinking = false;
      },
    });
    if (this.file) { this.file = undefined; this.image = undefined; }
    if (this.fileData) { this.fileData = undefined; this.image = undefined; }
    // }
    // else {
    //   this.chatService.sendMessageFile(this.currentUser().uuid, message, {
    //     file: this.file,
    //     image: this.image,
    //     fileData: this.fileData,
    //   }).subscribe({
    //     next: async (chatId) => {
    //       this.chatId = chatId;
    //       await this.sidebar.getChats();
    //       this.router.navigate([this.paths.chat.path.replace(':id', chatId)]);
    //       this.isThinking = false;
    //     },
    //     error: (err: ChatError) => {
    //       console.error(err);
    //       if (err.code === 3) {
    //         this.messages = [...this.messages, Content.outOfMana()]
    //       }
    //       this.isThinking = false;
    //     },
    //   });
    // }
  }

  toggleMenu() {
    this.sidebar.toggleMenu();
  }

  getMessages() {
    if (this.chatId == '') return new Observable<Content[]>(() => { [] }).subscribe();
    return this.chatService.getMessages(this.currentUser().uuid, this.chatId).subscribe({
      next: (messages) => {
        this.messages = messages;
        this.isThinking = false;
      },
      error: (err) => {
        console.error(err);
        this.isThinking = false;
        this.router.navigate([this.paths.chatbot.path]);
      },
    });
  }

  play() {
    this.flutterState.setMessage('/play');
  }

  end() {
    this.flutterState.setMessage('/end');
  }

  updateThinking(isThinking: boolean) {
    this.isThinking = isThinking;
  }

  selectFile(file: File) {
    this.file = file;
  }

  selectImage(image: FilePlaceholder) {
    this.image = image;
  }

  removeImage() {
    this.file = undefined;
    this.fileData = undefined;
    this.image = undefined;
  }

  selectFileFromStorage(file: FileData) {
    this.fileData = file;
  }

  transcript($event: string) {
    this.manaCtrl.refresh();
  }

  onLikeMessage({ messageId, like }: { messageId: string; like: LikeContent; }) {
    firstValueFrom(this.chatService.likeContent(this.currentUser().uuid, this.chatId, messageId, like)).then(
      (res) => {
        this.messages.find(message => message.id === messageId)!.like = like;
      }, (err) => {
        console.error(err);
      }
    );
  }

  onUndoMessage(message: Content) {
    firstValueFrom(this.chatService.deleteMessageFrom(this.currentUser().uuid, this.chatId, message.createdAt)).then(
      (res) => {
        this.getMessages();
      }, (err) => {
        console.error(err);
      }
    )
  }

  onRemoveMessage(message: Content) {
    firstValueFrom(this.chatService.deleteMessage(this.currentUser().uuid, this.chatId, message.id)).then(
      (res) => {
        this.getMessages();
      }, (err) => {
        console.error(err);
      }
    )
  }

  sendReviewMessage(message: string) {
    this.reviewMessage = message;
  }

  runCommand(message: string) {
    if (message == '/end') {
      this.end();
    }
    else {
      if (message.startsWith('/win')) {
        this.end();
      }
      else if (message.startsWith('/play')) {
        const game = message.split(' ')[1];
        if (game) this.play();
      }
      firstValueFrom(this.chatService.sendCommand(message, this.selectedModels[0])).then(
        (res) => {
          if (typeof res === 'string' && res != '') {
            this.messages = [...this.messages, CommandContent.reply(res, this.selectedModels[0])];
          }
          else if (res instanceof CommandContent) {
            this.messages = [...this.messages, res];
          }
        }
      )
    }
  }

}

