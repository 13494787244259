import { IAuthCredential } from '@domain/auth/i-auth-service';

export class EmailPasswordCredential implements IAuthCredential {
  email: string;
  password: string;
  provider = undefined;
  accessToken = undefined;

  constructor({ email, password }: { email: string; password: string }) {
    this.email = email;
    this.password = password;
  }

  toJson() {
    return {
      username: this.email,
      password: this.password,
    };
  }
}
export class AuthCredential implements IAuthCredential {
  email = undefined;
  password = undefined;
  provider: string;
  accessToken: string;
  idToken?: string;

  constructor({ provider, accessToken, idToken }: { provider: string; accessToken: string, idToken?: string }) {
    this.provider = provider;
    this.accessToken = accessToken;
    this.idToken = idToken;
  }

  toJson() {
    const json: any = {
      provider: this.provider,
      access_token: this.accessToken,
    };
    if (this.idToken) {
      json['id_token'] = this.idToken;
    }
    return json;
  }
}
