import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AIModel, FileData, FilePlaceholder } from '@share-utils/data';
import { IFileService, ISource } from '@share-utils/domain';
import { getBase64 } from '@share-utils/utils';
import { isMobile } from '@share-utils/view';
import { unionBy } from 'lodash-es';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { LatexComponent } from '../../latex/latex.component';
import { FileSelectionComponent } from '../file-selection/file-selection.component';

@Component({
  selector: 'chatbox',
  standalone: true,
  imports: [CommonModule, FormsModule, LottieComponent, MatMenuModule, FileSelectionComponent, LatexComponent, ImageCropperComponent],
  templateUrl: './chatbox.component.html',
})
export class ChatboxComponent implements OnInit, OnChanges, AfterViewInit {
  sanitizer = inject(DomSanitizer);

  accept!: string;
  text: string = '';
  options: AnimationOptions = {
    path: './assets/animations/loading.json',
    loop: true,
    autoplay: true,
  };
  isInit: boolean = false;
  placeholder: FilePlaceholder | null = null;
  showFileSelection = false;
  file: File | null = null;
  shouldSplit: boolean = false;
  scrollHeightLevel = 1;
  captureMathPictureStep = 0; // 0: not started, 1: started drop, 2: done drop, 3: transcripting, 4: done transcripting, 5: error
  error: string | null = null;
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';
  transform: ImageTransform = {
    rotate: 0,
  };
  highlightedText = '';
  showModels = false;
  showSources = false;
  selectedModels: AIModel[] = [];
  search = '';
  showSourceActions = false;
  deleteTagCursorPositions: number[] = [];
  isReviewing: boolean = false;
  maxScrollHeight = 15;

  @Input() isThinking: boolean = false;
  @Input() isGaming: boolean = false;
  @Input() hasFileFeature: boolean = false;
  @Input() hasSourceFeature: boolean = false;
  @Input() fileService!: IFileService;
  @Input() userId: string = '';
  @Input() highlightSources: string[] = [];
  @Input() modelsList: AIModel[] = [AIModel.kyo()];
  @Input() sourcesList: ISource[] = [];

  @Output() sendMessage = new EventEmitter<string>();
  @Output() sendReviewMessage = new EventEmitter<string>();
  @Output() sendMathMessage = new EventEmitter<string>();
  @Output() endGame = new EventEmitter<void>();
  @Output() selectFile = new EventEmitter<File>();
  @Output() selectFileFromStorage = new EventEmitter<FileData>();
  @Output() selectImage = new EventEmitter<FilePlaceholder>();
  @Output() removeImage = new EventEmitter();
  @Output() transcript = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<void>();
  @Output() modelsChangeEvent = new EventEmitter<AIModel[]>();
  @Output() sourceChangeEvent = new EventEmitter<ISource>();
  @Output() onAddFileEvent = new EventEmitter<void>();
  @Output() onSuggestionEvent = new EventEmitter<void>();

  @ViewChild('askInput') textareaElm!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('file') fileElm!: ElementRef<HTMLInputElement>;
  @ViewChild('errorDialog') errorDialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('backdrop') backdropElm!: ElementRef<HTMLDialogElement>;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.maxScrollHeight = Math.floor((window.innerHeight - 275) / 36);
  }

  ngOnInit(): void {
    this.accept = this.fileService?.accept || '';
    this.selectedModels = [this.modelsList[0]];
    this.modelsChangeEvent.emit(this.selectedModels);
    this.maxScrollHeight = Math.floor((window.innerHeight - 275) / 36);
  }

  filteredModels() {
    if (this.search == '') return this.modelsList;
    return this.modelsList.filter(m => m.hashTag.toLowerCase().includes(this.search.toLowerCase()));
  }
  filteredSources() {
    if (this.search == '') return this.sourcesList;
    return this.sourcesList.filter(m => m.hashTag.toLowerCase().includes(this.search.toLowerCase()));
  }

  ngAfterViewInit(): void {
    this.isInit = true;
  }

  ask() {
    if (this.text === '') return;
    let text = this.text.trim().replace(/\n/g, '<br>');
    this.sourcesList.filter(x => x.using).forEach(source => {
      text = text.replace(new RegExp(source.hashTag, 'g'), source.name);
    })
    this.sendMessage.emit(text);
    this.removeText();
    this.placeholder = null;
    this.showSourceActions = false;
  }

  getSuggestion() {
    this.onSuggestionEvent.emit();
    this.showSourceActions = false;
  }

  onCtrlEnter() {
    this.text += '\n';
  }

  onEnter($event: Event) {
    if (isMobile()) {
      this.text += '\n';
    }
    else { this.ask(); $event.preventDefault(); }
  }

  onDeleteFile() {
    if (!this.hasFileFeature) return;
    if (this.placeholder == null) return;
    this.removeImage.emit();
    this.placeholder = null;
    this.file = null;
  }

  async onFileSelected($event: Event) {
    if (!this.hasFileFeature) return;
    if ($event.target == null) return;
    const target = $event.target as HTMLInputElement;
    // const reader = new FileReader();
    if (target.files && target.files[0]) {
      const file = target.files[0];
      if (file.type.split('/')[0] !== 'image' && file.size > 10 * 1024 * 1024) {
        this.error = "Không thể tải ảnh lớn hơn 10 MB";
        this.errorDialogElm.nativeElement.showModal();
        return;
      }
      if (file.size > 20 * 1024 * 1024) {
        this.error = "Không thể tải ảnh lớn hơn 20 MB";
        this.errorDialogElm.nativeElement.showModal();
        return;
      }
      this.file = file;
      if (this.captureMathPictureStep == 1) {
        this.imageChangedEvent = $event;
        return;
      }
      // reader.readAsDataURL(target.files[0]);
      // if (file.type.split('/')[0] == 'image') {
      this.placeholder = new FilePlaceholder(file.name, file.type, file.size);
      this.selectImage.emit(this.placeholder);
      // }
      this.selectFile.emit(file);
      const base64 = (await getBase64(file)) as string;
      const type = file.type.split('/')[0];
      if (type == 'image') this.placeholder.base64 = base64;
      // if (type == 'video') {
      //   const image = await videoToImage(this.file, { frameTimeInSeconds: 0.5, extension: 'png' });
      //   console.log(image);
      // }
      this.selectImage.emit(this.placeholder);
    }
  }

  async onloadend(reader: FileReader) {
    if (!this.hasFileFeature || this.captureMathPictureStep > 0) return;
    if (this.placeholder == null || this.file == null) return;
    const result = reader.result as string;
    const splits = result.split(',');
    if (splits[0] == 'image') this.placeholder.base64 = reader.result as string;
    // if (splits[0] == 'video') {
    // const image = await videoToImage(this.file, { frameTimeInSeconds: 0.5, extension: 'png' });

    // this.placeholder.base64 = reader.result as string;
    // }
    // if (splits[0].includes('image/png')) {
    //   this.placeholder.mimeType = 'image/png'
    // }
    // else if (splits[0].includes('image/jpg')) {
    //   this.placeholder.mimeType = 'image/jpg'
    // }
    this.selectImage.emit(this.placeholder);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isInit) return;
    if (changes['isThinking']) {
      if (!changes['isThinking'].currentValue) {
        setTimeout(() => {
          if (this.textareaElm) this.textareaElm.nativeElement.focus();
        }, 100);
      }
    }
  }

  exit() {
    this.endGame.emit()
  }

  onSelectFileFromStorage(file: FileData) {
    if (!this.hasFileFeature) return;
    this.placeholder = FilePlaceholder.fromFileData(file);
    if (this.placeholder == null) return;
    this.showFileSelection = false;
    this.selectFileFromStorage.emit(file);
    this.selectImage.emit(this.placeholder);
  }

  renderTextInput() {
    setTimeout(() => {
      if (this.text == '' || this.text == '\n') {
        this.scrollHeightLevel = 1;
        return;
      }
      if (this.textareaElm == undefined) return;
      const scrollHeight = this.textareaElm.nativeElement.scrollHeight;
      const newScrollHeightLevel = (scrollHeight - 8) / 36;
      if (newScrollHeightLevel > this.scrollHeightLevel) {
        if (newScrollHeightLevel >= this.maxScrollHeight) { this.scrollHeightLevel = this.maxScrollHeight; return; }
        this.scrollHeightLevel = Math.round(newScrollHeightLevel);
      }
    }, 200);
  }

  onValueChange(newValue: string) {
    this.renderHighlights(newValue);
    this.updateSelectedModels(newValue);
    if (this.isReviewing) {
      this.sendReviewMessage.emit(newValue.replace(/\n/g, '\\r\n'));
    }
  }

  onKeydown($event: KeyboardEvent) {
    this.renderTextInput();

    switch ($event.key) {
      case '@':
        if (this.modelsList.length == 1) break;
        this.showModels = true;
        this.showSources = false;
        this.search = '@';
        break;
      case '#':
        this.showSources = true;
        this.showModels = false;
        this.search = '#';
        break;
      case ' ':
      case 'Escape':
        if (this.showModels) this.showModels = false;
        if (this.showSources) this.showSources = false;
        this.search = '';
        break;
      case 'ArrowRight':
        if (this.showModels) {
          if (this.filteredModels().length == 1) {
            this.selectModel(this.filteredModels()[0]);
          }
        }
        if (this.showSources) {
          if (this.filteredSources().length == 1) {
            this.selectSource(this.filteredSources()[0]);
          }
        }
        break;
      case 'Backspace':
        this.search = this.search.substring(0, this.search.length - 1);
        this.checkDeleteTag(($event.currentTarget as HTMLTextAreaElement).selectionEnd);
        break;

      default:
        if ($event.key.length == 1)
          this.search += $event.key;
        break;
    }
  }
  checkDeleteTag(selectionEnd: number) {
    if (this.deleteTagCursorPositions[selectionEnd - 1] > 0) {
      for (let i = selectionEnd - 1; i <= this.deleteTagCursorPositions.length; i++) {
        // if (i == this.deleteTagCursorPositions.length - 1) {
        //   const asd = this.text.slice(0, i - 1 - this.deleteTagCursorPositions[i - 1]);
        //   console.log(asd);

        // }
        if (i == this.deleteTagCursorPositions.length - 1 || this.deleteTagCursorPositions[i] > this.deleteTagCursorPositions[i + 1]) {
          const endPosition = i - this.deleteTagCursorPositions[i] + 1;
          let newText = this.text.slice(0, endPosition);
          if (i < this.deleteTagCursorPositions.length - 1) {
            newText += this.text.slice(i + 1);
          }
          this.text = newText + ' ';
          this.textareaElm.nativeElement.selectionStart = endPosition;
          this.textareaElm.nativeElement.selectionEnd = endPosition;
          this.addText('');
          break;
        }
      }

    }
  }

  updateSelectedModels(text: string) {
    const result: AIModel[] = [];
    text = text != '' ? text
      .replace(/\n$/g, "\n\n") : '';

    this.modelsList.forEach(x => {
      if (text.includes(x.hashTag)) {
        result.push(x);
      }
    })
    const models = unionBy(result, 'hashTag');
    this.selectedModels = models.length > 0 ? models : [this.modelsList[0]];
    this.modelsChangeEvent.emit(this.selectedModels);
  }

  addText(text: string) {
    const currentCursor = this.textareaElm.nativeElement.selectionEnd;
    this.text = this.text.substring(0, currentCursor) + text + this.text.substring(currentCursor);
    this.renderTextInput();
    this.renderHighlights(this.text);
    this.updateSelectedModels(this.text);
    setTimeout(() => {
      this.textareaElm.nativeElement.focus();
      this.textareaElm.nativeElement.setSelectionRange(currentCursor + text.length, currentCursor + text.length);
    }, 100);
  }

  renderHighlights(text: string) {
    const originalText = text;
    text = text ? text
      .replace(/\n$/g, "\n\n") : '';
    // Highlight models, ignore if only one
    if (this.modelsList.length > 1) {
      this.modelsList.map(x => x.hashTag).forEach(x => {
        text = text
          .replace(new RegExp(x, 'g'), "<span class='highlight-model'>$&</span>");
      })
    }

    // Highlight sources
    this.sourcesList.map(x => x.hashTag).forEach(x => {
      text = text
        .replace(new RegExp(x, 'g'), "<span class='highlight-source'>$&</span>");
    })
    this.highlightedText = text;
    this.deleteTagCursorPositions = [];
    const findingTags = [...this.modelsList.map(x => x.hashTag), ...this.sourcesList.map(x => x.hashTag)];
    // Find all start and end positions of tags in the text
    for (let i = 0; i < originalText.length; i++) {
      if (findingTags.find(x => originalText.substring(i, i + x.length) == x) != undefined) {
        const findedTag = findingTags.find(x => originalText.substring(i, i + x.length) == x)!;
        for (let j = 0; j < findedTag.length; j++) {
          this.deleteTagCursorPositions.push(j + 1);
        }
        i += findedTag.length - 1;
      }
      else {
        this.deleteTagCursorPositions.push(0);
      }
    }
    // console.log(this.deleteTagCursorPositions);
  }

  handleScroll() {
    var scrollTop = this.textareaElm.nativeElement.scrollTop;
    this.backdropElm.nativeElement.scrollTop = scrollTop;

    var scrollLeft = this.textareaElm.nativeElement.scrollLeft;
    this.backdropElm.nativeElement.scrollLeft = scrollLeft;
  }

  removeText(shouldUpdateModels = false) {
    if (shouldUpdateModels) {
      this.text = '';
    }
    else {
      if (this.selectedModels.length == 1 && this.selectedModels[0].hashTag == this.modelsList[0].hashTag) {
        this.text = '';
      }
      else {
        this.text = this.selectedModels.map(model => model.hashTag).join(' ') + ' ';
      }
    }
    this.renderHighlights(this.text);
    this.updateSelectedModels(this.text);
    this.renderTextInput();
    this.captureMathPictureStep = 0;
    this.file = null;
    this.placeholder = null;
    this.removeImage.emit();
    setTimeout(() => {
      this.textareaElm.nativeElement.focus();
    }, 100);
  }

  captureMathPicture() {
    this.captureMathPictureStep = 1;
    this.fileElm.nativeElement.click();
  }

  onFileCancel() {
    this.file = null;
    this.fileElm.nativeElement.value = '';
    this.captureMathPictureStep = 0;
  }

  getMathWithSolution() {
    this.sendMathMessage.emit(`Hướng dẫn mình cách giải bài toán này: ${this.text}`);
    this.removeText();
    this.onFileCancel();
  }
  getMathResult() {
    this.sendMathMessage.emit(`Chỉ cần giúp mình tìm kết quả nhanh cho bài toán này (không cần hướng dẫn giải): ${this.text}`);
    this.removeText();
    this.onFileCancel();
  }

  doneCropping() {
    // this.file = this.croppedFile;
    this.captureMathPictureStep = 2;
    this.transcriptImage();
  }


  // Image cropping
  // fileChangeEvent(event: Event): void {
  //   this.imageChangedEvent = event;
  // }
  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? '');
    // event.blob can be used to upload the cropped image
    this.file = new File([event.blob!], this.file!.name, {
      type: event.blob!.type,
      lastModified: Date.now()
    });
  }
  imageLoaded(image: LoadedImage) {
    this.captureMathPictureStep = 2;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // this.captureMathPictureStep = 5;
    // this.error = '';
  }
  async transcriptImage() {
    if (!this.file) return;
    this.captureMathPictureStep = 3;
    this.fileService.transcriptFile(this.userId, this.file, this.file.type).subscribe({
      next: (transcript) => {
        this.addText(transcript);
        this.captureMathPictureStep = 4;
        this.transcript.emit(transcript);
      },
      error: (err) => {
        if (err.code == 3) {
          this.error = "Không thể tải ảnh lên";
        }
        else if (err.code == 4) {
          this.error = "Ảnh không đúng định dạng cho phép";
        }
        else if (err.code == 5) {
          this.error = "Ảnh quá lớn";
        }
        else if (err.code == 6) {
          this.error = "Không đủ mana";
        }
        else
          this.error = "Lỗi chưa xác định";
        this.errorDialogElm.nativeElement.showModal()
        this.onFileCancel();
      }
    });
    this.file = null;
  }

  decreaseTextareaSize() {
    if (this.scrollHeightLevel <= 6) { this.scrollHeightLevel = 3; return; }
    this.scrollHeightLevel -= 3;

  }
  increaseTextareaSize() {
    if (this.scrollHeightLevel >= this.maxScrollHeight) { this.scrollHeightLevel = this.maxScrollHeight; return; }
    this.scrollHeightLevel += 3;
  }
  onResize($event: UIEvent) {
    console.log($event);

  }

  inputFocus() {
    this.onFocus.emit();
  }

  selectModel(model: AIModel) {
    const currentCursor = this.textareaElm.nativeElement.selectionEnd;
    // const charBeforeCursor = this.text[currentCursor - 1];
    // Find the @ from current cursor to left side
    const lastIndexOfAt = this.text.substring(0, currentCursor).lastIndexOf('@');
    const lastIndexOfSpace = this.text.substring(0, currentCursor).lastIndexOf(' ');
    if (lastIndexOfAt > lastIndexOfSpace) {
      this.text = this.text.substring(0, lastIndexOfAt) + this.text.substring(currentCursor);
      this.textareaElm.nativeElement.selectionEnd = lastIndexOfAt;
    }
    this.addText(` ${model.hashTag} `);
    this.showModels = false;
  }
  rotate() {
    if (this.transform.rotate) this.transform.rotate += 1;
    else {
      this.transform.rotate = 1;
    }
  }

  selectSource(source: ISource) {
    const currentCursor = this.textareaElm.nativeElement.selectionEnd;
    // const charBeforeCursor = this.text[currentCursor - 1];
    // Find the @ from current cursor to left side
    const lastIndexOfAt = this.text.substring(0, currentCursor).lastIndexOf('#');
    const lastIndexOfSpace = this.text.substring(0, currentCursor).lastIndexOf(' ');
    if (lastIndexOfAt > lastIndexOfSpace) {
      this.text = this.text.substring(0, lastIndexOfAt) + this.text.substring(currentCursor);
      this.textareaElm.nativeElement.selectionEnd = lastIndexOfAt + 1;
      this.addText(` ${source.hashTag} `);
    }
    this.showSources = false;
    this.sourceChangeEvent.emit(source);
  }

  deselectSource(source: ISource) {

  }

  toggleModelSelection() {
    if (this.modelsList.length == 1) return;
    this.search = ''; this.showModels = !this.showModels;
  }

}

// export const videoToImage = (
//   videoFile: File,
//   options: {
//     frameTimeInSeconds?: number
//     filename?: string
//     extension?: string
//   } = {
//       frameTimeInSeconds: 0.5,
//       extension: "png"
//     }
// ): Promise<File> => {
//   return new Promise<File>((resolve) => {
//     const canvas = document.createElement('canvas')
//     const video = document.createElement('video')
//     const source = document.createElement('source')
//     const context = canvas.getContext('2d')
//     const urlRef = URL.createObjectURL(videoFile)

//     video.style.display = 'none'
//     canvas.style.display = 'none'

//     source.setAttribute('src', urlRef)
//     video.setAttribute('crossorigin', 'anonymous')
//     video.setAttribute('preload', 'metadata')

//     video.appendChild(source)
//     document.body.appendChild(canvas)
//     document.body.appendChild(video)

//     if (!context) {
//       return
//     }

//     video.currentTime = options.frameTimeInSeconds ?? 0
//     video.load()

//     video.addEventListener('loadedmetadata', function () {
//       canvas.width = video.videoWidth
//       canvas.height = video.videoHeight
//     })

//     video.addEventListener('loadeddata', function () {
//       setTimeout(() => {
//         context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

//         canvas.toBlob((blob) => {
//           if (!blob) return
//           resolve(
//             new File([blob], (options.filename || videoFile.name) + "_preview." + options.extension, {
//               type: 'image/' + options.extension
//             })
//           )
//           URL.revokeObjectURL(urlRef)

//           video.remove()
//           canvas.remove()
//         }, 'image/' + options.extension)
//       }, 2000)
//     })
//   })
// }
